@font-face {
  font-family: 'UniversLTStd';
  src: url('./static/fonts/UniversLT/UniversLTStd.woff') format('woff')
};

@font-face {
  font-family: 'UniversLTStd-Bold';
  src: url('./static/fonts/UniversLT/UniversLTStd-Bold.woff') format('woff')
};

@font-face {
  font-family: 'CoreCircus-Reg';
  src: url('./static/fonts/CoreCircus/S-Core-CoreCircus.otf'),
       url('./static/fonts/CoreCircus/s-core-corecircus-webfont.woff2') format('woff2'),
       url('./static/fonts/CoreCircus/s-core-corecircus-webfont.woff') format('woff');
};

html, body {
  font-family: 'Roboto', sans-serif;
  background: #F0F0F0;
  overflow-y: scroll;
}